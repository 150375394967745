import { writable } from 'svelte/store';

// private Long id;
// private String externalId; userId
// private String name;
// private String email;
// private Long organizationId;
export const user = writable({});
export const conversation = writable({});

export const subscribeOnUser = (stompClient) => {
  const destination = '/user/topic/user';

  return new Promise((resolve) =>
    stompClient.subscribe(destination, (payload) => {
      const body = JSON.parse(payload.body);
      user.set(body);
      resolve(body);
    })
  );
};

export const subscribeOnConversation = (
  stompClient,
  studentId,
  organizationId,
  currentPath
) => {
  const destination = '/user/topic/conversation';

  return new Promise((resolve) => {
    stompClient.subscribe(
      destination,
      (payload) => {
        const body = JSON.parse(payload.body);
        conversation.set(body);
        resolve(body);
      },
      { studentId, organizationId, pathContext: currentPath }
    );
  });
};
