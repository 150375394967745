import { writable } from 'svelte/store';
import { SHOW_HINTS_KEY, SHOW_ICON_KEY } from '../contants.js';

function createWidgetSettingsStore() {
  const { update, subscribe } = writable({
    isVisibleHints: true,
    isVisibleIcon: true,
    isVisibleDot: false,
    settings: null,
    organizationId: null,
    courses: [],
  });

  const hideHints = () => {
    localStorage.setItem(SHOW_HINTS_KEY, 'false');
    update((state) => ({
      ...state,
      isVisibleHints: false,
    }));
  };

  const hideHintsAndIcon = () => {
    localStorage.setItem(SHOW_HINTS_KEY, 'false');
    localStorage.setItem(SHOW_ICON_KEY, 'false');
    update((state) => ({
      ...state,
      isVisibleHints: false,
      isVisibleIcon: false,
      isVisibleDot: true,
    }));
  };

  const showIcon = () => {
    localStorage.setItem(SHOW_ICON_KEY, 'true');
    update((state) => ({
      ...state,
      isVisibleIcon: true,
      isVisibleDot: false,
    }));
  };

  const showIconAndHints = () => {
    localStorage.setItem(SHOW_HINTS_KEY, 'true');
    localStorage.setItem(SHOW_ICON_KEY, 'true');
    update((state) => ({
      ...state,
      isVisibleHints: true,
      isVisibleIcon: true,
      isVisibleDot: false,
    }));
  };

  return {
    update,
    subscribe,
    hideHints,
    hideHintsAndIcon,
    showIcon,
    showIconAndHints,
  };
}

export const widgetSettingsStore = createWidgetSettingsStore();
