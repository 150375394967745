<script>
  export let fillColor = '#dadada';
</script>

<svg
  fill="none"
  height="20"
  viewBox="0 0 20 20"
  width="20"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18.7639 10.0605H8.0556M1 6.06058H3.5M2 10.0605H3.5M2.5 14.0605H3.5M7.96153 10.5501L7.07002 13.5091C6.73252 14.6293 6.56376 15.1894 6.70734 15.5238C6.83199 15.8142 7.08656 16.0286 7.39391 16.102C7.74792 16.1866 8.2711 15.925 9.3175 15.4018L17.1378 11.4916C18.059 11.031 18.5197 10.8007 18.6675 10.489C18.7961 10.2178 18.7961 9.90325 18.6675 9.63205C18.5197 9.32035 18.059 9.09005 17.1378 8.62945L9.3068 4.71397C8.2633 4.19223 7.74156 3.93136 7.38789 4.01557C7.0808 4.0887 6.82627 4.30253 6.70128 4.59239C6.55731 4.92624 6.72427 5.48516 7.05819 6.60301L7.96261 9.63065C8.0137 9.80165 8.0392 9.88715 8.0493 9.97425C8.0583 10.0515 8.0582 10.1295 8.049 10.2068C8.0387 10.2939 8.013 10.3793 7.96153 10.5501Z"
    stroke={fillColor}
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.488"
  />
</svg>
