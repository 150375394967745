<script>
  import { fade } from 'svelte/transition';
  import { modalStore } from '../../storages/modalStore.js';
  import ReactionModal from '../modal/ReactionModal/index.svelte';
  import LikeSvg from '../../assets/images/thumb-up.svelte';
  import DislikeSvg from '../../assets/images/thumb-down.svelte';
  import { setReaction } from '../../services/socketConnection.js';
  import { conversation } from './../../storages/user-store';
  import { messagesStore } from './../../storages/message-store.js';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import { t } from '../../storages/translator.js';

  export let showReactions;
  export let message;
  export let msgReaction;
  export let retryHandler;
  export let showRetry = false;
  export let setIsHovered;
  $: color = $widgetSettingsStore.settings.color;
  $: isMsgAlreadyLiked = msgReaction === '👍';
  $: isMsgAlreadyDisliked = msgReaction === '👎';

  const handleClick = () => {
    retryHandler();
  };

  const handleReactionClick = (reaction) => () => {
    setIsHovered(false)();
    reaction === '👍'
      ? (isMsgAlreadyLiked = true)
      : (isMsgAlreadyDisliked = true);
    modalStore.showModal();
    modalStore.update((state) => {
      return {
        ...state,
        children: {
          component: ReactionModal,
          props: {
            message: message,
            showLikeIcon: reaction === '👍',
            placeholder:
              reaction === '👍'
                ? $t('evahelper.reaction.positivePlaceholder')
                : $t('evahelper.reaction.negativePlaceholder'),
          },
        },
      };
    });
    setReaction(message.id, reaction, 'REACTION', $conversation.id);
    messagesStore.setMsgReaction(message.id, reaction);
  };
</script>

<div class="container">
  {#if showRetry}
    <div
      class="retry"
      style="--color:{color};"
      in:fade={{ duration: 200 }}
      out:fade={{ duration: 200 }}
      on:click={handleClick}
    >
      {$t('evahelper.message.retry')}
    </div>
  {/if}
  {#if showReactions && !isMsgAlreadyLiked && !isMsgAlreadyDisliked}
    <div
      in:fade={{ duration: 200 }}
      out:fade={{ duration: 200 }}
      style="--color:{color};"
      class="reaction-container"
    >
      <div
        class="cloud"
        style="--color:{color};"
        on:click={handleReactionClick('👍')}
      >
        <LikeSvg fillColor="#c7c7c7" />
      </div>
      <div
        class="cloud"
        style="--color: {color}"
        on:click={handleReactionClick('👎')}
      >
        <DislikeSvg fillColor="#c7c7c7" />
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .container {
    padding: 0;
    margin: 0;
    position: absolute;
    box-sizing: initial;
    width: 100%;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    bottom: -8px;
    left: 6px;
  }
  .retry {
    pointer-events: auto;
    cursor: pointer;
    box-sizing: initial;
    white-space: nowrap;
    color: var(--color);
    background: #f2f2f7;
    border: 2px solid white;
    border-radius: 14px;
    padding: 0 12px;
    font-size: 12px;
    height: 24px;
    display: flex;
    align-items: center;
  }

  .reaction-container {
    box-sizing: initial;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f2f7;
    border: 2px solid white;
    border-radius: 14px;
    width: 50px;
    height: 24px;
    margin-right: 70px;
    margin-left: auto;
    gap: 2px;

    & .cloud {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      -webkit-tap-highlight-color: transparent;
      &:hover {
        border-radius: 50%;
        box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
        & svg {
          fill: var(--color);
        }
      }

      & svg {
        width: 24px;
        height: 16px;
      }
    }
  }
</style>
