import { writable } from 'svelte/store';

const defaultComponent = () => ({
  component: null,
  props: {},
});

function createModalStore() {
  const { update, subscribe } = writable({
    children: defaultComponent(),
    showModal: false,
  });

  const closeModal = () => {
    update((state) => {
      return {
        ...state,
        showModal: false,
      };
    });
  };

  const showModal = () => {
    update((state) => {
      return {
        ...state,
        showModal: true,
      };
    });
  };

  return {
    update,
    subscribe,
    closeModal,
    showModal,
  };
}

export const modalStore = createModalStore();
