<script>
  export let fillColor = '#dadada';
</script>

<svg
  fill="none"
  height="20"
  viewBox="0 0 20 20"
  width="20"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.1146 10.0605H5.40627M5.31219 10.5501L4.42068 13.5091C4.08318 14.6293 3.91443 15.1894 4.05801 15.5238C4.18266 15.8142 4.43723 16.0286 4.74458 16.102C5.09859 16.1866 5.62177 15.925 6.66816 15.4018L14.4885 11.4916C15.4097 11.031 15.8704 10.8007 16.0182 10.489C16.1468 10.2178 16.1468 9.90325 16.0182 9.63205C15.8704 9.32035 15.4097 9.09005 14.4885 8.62945L6.65746 4.71397C5.61396 4.19223 5.09222 3.93136 4.73855 4.01557C4.43146 4.0887 4.17693 4.30253 4.05194 4.59239C3.90797 4.92624 4.07494 5.48516 4.40886 6.60301L5.31328 9.63065C5.36437 9.80165 5.38987 9.88715 5.39997 9.97425C5.40897 10.0515 5.40886 10.1295 5.39966 10.2068C5.38936 10.2939 5.36366 10.3793 5.31219 10.5501Z"
    stroke={fillColor}
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.488"
  />
</svg>
