<script>
  import dateFormat from 'dateformat';
  import { onMount } from 'svelte';

  import { useCodeFormatter } from '../../helpers/useCodeFormatter.js';

  import MessageBubbleSent from './../message/MessageBubbleSent.svelte';
  import MessageBubbleTyping from './../message/MessageBubbleTyping.svelte';
  import MessageBubbleReceive from './../message/MessageBubbleReceive.svelte';

  export let retryHandler;
  export let message = null;
  export let isLastEvaMsg = false;
  export let isFirstEvaMsg = false;
  export let isFullScreen = false;
  export let isFirstOpen;
  export let isScrollButtonShowed;

  const { formatTextToCodeTokens } = useCodeFormatter();

  let code;
  let sendTime;
  let messageComponent;

  onMount(() => {
    code = message?.typing ? '' : formatTextToCodeTokens(message?.text);
    sendTime = message?.typing
      ? ''
      : dateFormat(new Date(Date.parse(message?.sendTime)), ' hh:MM TT');
    messageComponent = getMessageComponentByItem(message);
  });

  const getMessageComponentByItem = ({ senderId, typing }) => {
    if (senderId) {
      return MessageBubbleSent;
    }

    if (typing) {
      return MessageBubbleTyping;
    }

    return MessageBubbleReceive;
  };
</script>

<svelte:component
  this={messageComponent}
  {code}
  {isFirstEvaMsg}
  {isFirstOpen}
  {isFullScreen}
  {isLastEvaMsg}
  {isScrollButtonShowed}
  {message}
  {retryHandler}
  {sendTime}
/>
