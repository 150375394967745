import { createSocketClient } from '../Index';
import { Stomp } from 'stompjs/lib/stomp.min.js';
import { setConnection } from './socketConnection.js';
import { authStore } from '../storages/authStore';
import { widgetSettingsStore } from '../storages/widgetSettingsStore';
import { getWidgetSettings } from '../api/settings';
import { translateAndSaveLanguage } from './../storages/translator';
import {
  defaultWidgetPosition,
  EVA_LOCALES,
  SHOW_HINTS_KEY,
  SHOW_ICON_KEY,
} from '../contants.js';

export const connectWebSocket = async (currentPath) => {
  try {
    const organizationId = widgetSettingsStore.organizationId;
    let id = localStorage.getItem('userId');
    if (id == null) {
      id = (Math.random() + 1).toString(36).substring(7);
      localStorage.setItem('userId', id);
    }
    let socketClient = createSocketClient(id, organizationId);
    const connection = await setConnection(
      Stomp.over(socketClient),
      id,
      currentPath
    ).catch((reason) => console.log(reason));

    authStore.update((state) => ({
      ...state,
      isSocketConnectionEstablished: true,
    }));

    return connection;
  } catch (error) {
    console.error(error);
    authStore.update((state) => ({
      ...state,
      isSocketConnectionEstablished: false,
    }));
  }
};

export const init = async () => {
  try {
    const { settings, language, courses, organizationId } =
      await getWidgetSettings();
    if (language) await translateAndSaveLanguage(language.toLowerCase());

    const defaultColor = 'rgba(102, 135, 251, 1)';
    const defaultShadowColor = defaultColor.replace(/[\d\.]+\)$/g, '0.4)');
    if (!settings.color) {
      settings.color = defaultColor;
    }

    settings.botName = settings.botName ?? 'Eva';
    settings.position = settings.position ?? defaultWidgetPosition;
    if (settings.showNamePlate === null) {
      settings.showNamePlate = true;
    }

    settings.shadowColor = settings.color
      ? settings.color.replace(/[\d\.]+\)$/g, '0.4)')
      : defaultShadowColor;

    settings.language = language;

    const { isVisibleHints, isVisibleIcon, isVisibleDot, botName } =
      initLocalStorageValues(settings);
    settings.botName = botName;
    widgetSettingsStore.update((state) => ({
      ...state,
      isVisibleHints,
      isVisibleIcon,
      isVisibleDot,
      settings,
      courses,
      organizationId,
    }));

    authStore.update((state) => ({
      ...state,
      areWidgetSettingsReceived: true,
    }));
  } catch (error) {
    console.error(error);
    authStore.update((state) => ({
      ...state,
      areWidgetSettingsReceived: false,
    }));
  }
};

export const initLocalStorageValues = (settings) => {
  const { language, botName } = settings;
  const locales = JSON.parse(localStorage.getItem(EVA_LOCALES));
  const updatedBotName =
    botName === 'Eva'
      ? locales[language.toLowerCase()]['evahelper.eva.name']
      : botName;

  let isVisibleHints = localStorage.getItem(SHOW_HINTS_KEY);
  let isVisibleIcon = localStorage.getItem(SHOW_ICON_KEY);
  isVisibleHints = isVisibleHints === null ? true : isVisibleHints === 'true';
  isVisibleIcon = isVisibleIcon === null ? true : isVisibleIcon === 'true';
  const isVisibleDot = !isVisibleIcon;

  return {
    isVisibleHints: Boolean(isVisibleHints && isVisibleIcon),
    isVisibleIcon,
    isVisibleDot,
    botName: updatedBotName,
  };
};
