import {
  subscribeOnConversation,
  subscribeOnUser,
} from './../storages/user-store';
import { messagesStore } from './../storages/message-store';
import { createSocketClient } from './../Index.js';

export let stompClient = null;

export const setConnection = (stomp, id, currentPath) => {
  stompClient = stomp;

  stompClient.debug = PRODUCTION_MODE ? () => {} : console.log;

  return new Promise((resolve, reject) => {
    const connectCallback = async () => {
      const { id, organizationId } = await subscribeOnUser(stompClient);
      const { id: conversationId } = await subscribeOnConversation(
        stompClient,
        id,
        organizationId,
        currentPath
      );
      await subscribeOnMessage(conversationId);

      resolve(stompClient);
    };

    const errorCallback = () => {
      stompClient.disconnect();

      setTimeout(() => {
        const socket = createSocketClient(id);
        setConnection(Stomp.over(socket), id, currentPath)
          .then(resolve)
          .catch(reject);
      }, 2000);
    };

    stompClient.connect(
      { 'accept-version': '1.1' },
      connectCallback,
      errorCallback
    );
  });
};

const subscribeOnMessage = (conversationId) => {
  return new Promise((resolve) => {
    const destination = '/user/topic/completions';
    stompClient.subscribe(destination, messagesStore.handleMessages, {
      conversationId,
      'disable-subscription-history': true,
    });
    resolve();
  });
};

export const sendNewMessage = (comment) => {
  const requestSource = document.referrer || window.location.href;
  return stompClient.send(
    '/app/pupil.completions',
    {},
    JSON.stringify({
      comment,
      requestSource,
    })
  );
};

export const setReaction = (
  messageId,
  content,
  reactionType,
  conversationId
) => {
  return stompClient.send(
    '/app/pupil.replies',
    { conversationId },
    JSON.stringify({
      messageId,
      content,
      type: reactionType,
    })
  );
};

export const getMessages = async ({
  size,
  page,
  sortDirection,
  conversationId,
}) => {
  return stompClient.send(
    '/app/completions-history',
    { conversationId },
    JSON.stringify({
      size,
      page,
      sortDirection,
    })
  );
};
