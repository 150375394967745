import App from './Index.svelte';
import { translateAndSaveLanguage } from './storages/translator';
import './messages.css';

translateAndSaveLanguage();

const app = new App({
  target: document.body,
});

export default app;
