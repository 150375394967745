<script>
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';

  $: botName = $widgetSettingsStore.settings.botName;
</script>

<div class="eva-title">
  <p class="eva-title__name">{botName}</p>
</div>

<style>
  :global(#evahelper-ai) .eva-title {
    margin-bottom: 4px;
    margin-top: 0;
  }

  :global(#evahelper-ai) .eva-title__name {
    font-style: normal;
    margin: 0;
    color: black;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }
</style>
