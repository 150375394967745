<script>
  import { fade } from 'svelte/transition';
  import CodeBlock from './CodeBlock.svelte';
  import CodeWord from './CodeWord.svelte';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import LinkText from './LinkText.svelte';
  import { marked } from 'marked';
  const color = $widgetSettingsStore.settings.color;

  export let sendTime;
  export let code = [];
</script>

{#if code.length > 0}
  <div style="--color:{color};" class="sent-message" in:fade>
    <p class="sent-message__text">
      {#each code as group}
        {#if group.type === 'code-block'}
          <CodeBlock language={group.language} code={group.content} />
        {:else if group.type === 'code-word'}
          <CodeWord class="language- small-code">
            {@html group.content}
          </CodeWord>
        {:else if group.type === 'link-text'}
          <LinkText isMsgFromUser={true} content={group.content} />
        {:else}
          <span class="plain-markdown">{@html group.content || '<br>'}</span>
        {/if}
      {/each}
    </p>
    <time class="sent-message__time">
      {sendTime}
    </time>
  </div>
{/if}

<!-- TODO get rid of global styles -->
<style>
  :global(#evahelper-ai) .sent-message {
    position: relative;
    z-index: 1;
    align-self: flex-end;
    max-width: 70%;
    color: white;
    border-radius: 12px;
    background: var(--color);
    padding: 6px 10px;
  }

  :global(#evahelper-ai) .sent-message__time {
    text-align: right;
    font-weight: 300;
    font-size: 12px;
    align-self: flex-end;
    margin: 0;
    display: block;
  }

  :global(#evahelper-ai) .sent-message__text {
    margin: 0 0 10px;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    word-break: break-word;
    white-space: pre-wrap;
    color: white;
  }
</style>
