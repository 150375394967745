<script>
  import { fly } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';

  const color = $widgetSettingsStore.settings.color;
  const dispatch = createEventDispatcher();
  const handleClick = () => dispatch('click');
</script>

<button
  class="scroll-button"
  on:click|stopPropagation={handleClick}
  in:fly={{ delay: 100, duration: 400, y: 40 }}
  out:fly={{ delay: 100, duration: 400, y: 40 }}
>
  <svg
    class="scroll-button-icon"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="99.999"
      cy="100.005"
      r="95"
      transform="rotate(-90 99.999 100.005)"
      class="scroll-button__circle"
    />
    <path
      class="scroll-button__arrow"
      style="--color:{color};"
      d="M105.02 136.95C102.286 139.683 97.8542 139.683 95.1206 136.95L50.5728 92.4018C47.8392 89.6682 47.8392 85.236 50.5728 82.5024C53.3065 79.7687 57.7387 79.7687 60.4723 82.5024L100.07 122.1L139.668 82.5024C142.402 79.7687 146.834 79.7687 149.568 82.5024C152.301 85.236 152.301 89.6682 149.568 92.4018L105.02 136.95ZM107.07 131.858V132H93.0703V131.858H107.07Z"
    />
  </svg>
</button>

<style>
  :global(#evahelper-ai) .scroll-button {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    right: 13px;
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.12);
    transition: all 0.2s ease;
    border-radius: 50%;
    justify-content: center;
    background-color: transparent;
  }

  :global(#evahelper-ai) .scroll-button-icon {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 599px) {
    :global(#evahelper-ai) .scroll-button {
      width: 30px;
      height: 30px;
    }
  }

  :global(#evahelper-ai) .scroll-button__circle {
    fill: white;
  }

  :global(#evahelper-ai) .scroll-button__arrow {
    fill: #dadada;
    transition: 150ms fill;
  }

  :global(#evahelper-ai) .scroll-button:hover .scroll-button__arrow {
    fill: var(--color);
  }
</style>
