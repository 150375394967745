<script>
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';

  const color = $widgetSettingsStore.settings.color;
</script>

<div class="evahelper-ai-loader" style="--color:{color};">
  <div class="evahelper-ai-loader__one evahelper-ai-loader__circle" />
  <div class="evahelper-ai-loader__two evahelper-ai-loader__circle" />
  <div class="evahelper-ai-loader__three evahelper-ai-loader__circle" />
  <div class="evahelper-ai-loader__four evahelper-ai-loader__circle" />
</div>

<style>
  :global(#evahelper-ai) .evahelper-ai-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }

  :global(#evahelper-ai) .evahelper-ai-loader__circle {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  :global(#evahelper-ai) .evahelper-ai-loader__one {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  :global(#evahelper-ai) .evahelper-ai-loader__two {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  :global(#evahelper-ai) .evahelper-ai-loader__three {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  :global(#evahelper-ai) .evahelper-ai-loader__four {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
</style>
