<script>
  import { onDestroy, onMount } from 'svelte';
  import { init } from './services/auth';
  import { useMutationObserver } from './services/useMutationObserver';
  import github from 'svelte-highlight/styles/github';
  import { authStore } from './storages/authStore';
  import { widgetRuntimeStore } from './storages/widgetRuntimeStore';
  import { widgetSettingsStore } from './storages/widgetSettingsStore';

  import ToggleButton from './components/ToggleButton/index.svelte';
  import MessageForm from './components/MessageForm.svelte';
  import { matchPathnameWithCourseUrl } from './helpers/matchPathnameWithCourseUrl';

  const cssUrl = `${ASSETS_URL || '.'}/build/${HASH}.css`;
  const loading = init();

  $: isWidgetActive = $widgetSettingsStore.settings?.isActive;
  $: courses = $widgetSettingsStore.courses;
  $: isVisibleWidget = $authStore.areWidgetSettingsReceived && isValidPath;
  $: openedComponent = $widgetRuntimeStore.isVisible
    ? MessageForm
    : ToggleButton;

  let isValidPath;
  const mutationObserver = useMutationObserver();

  const checkPath = (pathname, query) => {
    const coursesPaths = Object.keys(courses);
    const url = matchPathnameWithCourseUrl(pathname, query, coursesPaths);
    if (!url) {
      return isWidgetActive;
    }

    widgetRuntimeStore.setCurrentPath(url);

    const currentCourse = courses[url];
    return currentCourse.isActive;
  };

  onMount(async () => {
    mutationObserver.createObserver((mutations) =>
      mutations.forEach(
        () =>
          (isValidPath = checkPath(
            window.location.pathname,
            window.location.search
          ))
      )
    );

    mutationObserver.observe(document.body);
  });

  onDestroy(() => mutationObserver?.disconnect());
</script>

<svelte:head>
  <meta
    content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no"
    name="viewport"
  />
  <link href="https://cdnjs.cloudflare.com" rel="dns-prefetch" />
  <link href="https://fonts.googleapis.com" rel="preconnect" />
  <link crossorigin href="https://fonts.gstatic.com" rel="preconnect" />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,700;1,400&display=swap"
    rel="stylesheet"
  />
  <link as="style" href={cssUrl} onload="this.rel='stylesheet'" rel="preload" />
  {@html github}
</svelte:head>

{#await loading then _}
  {#if isVisibleWidget}
    <div id="evahelper-ai">
      <svelte:component this={openedComponent} />
    </div>
  {/if}
{/await}

<style>
  #evahelper-ai {
    font-family: Roboto, Arial, sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  @media (max-width: 300px) {
    #evahelper-ai {
      display: none;
    }
  }
</style>
