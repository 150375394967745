<script>
  import { widgetRuntimeStore } from '../../storages/widgetRuntimeStore';
  import { connectWebSocket } from './../../services/auth';
  import { authStore } from '../../storages/authStore';
  import Hints from '../message/Hints.svelte';
  import EvaIcon from '../../assets/images/eva-icon.svelte';
  import PulsatingDot from '../PulsatingDot/PulsatingDot.svelte';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import CloseIcon from '../CloseIcon/index.svelte';
  import { t } from '../../storages/translator.js';
  import Tooltip from '../Tooltip/index.svelte';
  import { fade } from 'svelte/transition';
  import Curtain from '../Curtain/Curtain.svelte';
  import { onMount } from 'svelte';

  $: isConnectionEstablished = $authStore.isSocketConnectionEstablished;
  $: currentPath = $widgetRuntimeStore.currentPath;
  $: isVisibleDot = $widgetSettingsStore.isVisibleDot;
  $: isVisibleIcon = $widgetSettingsStore.isVisibleIcon;
  $: isVisibleHints = $widgetSettingsStore.isVisibleHints;
  $: innerWidth = 0;

  $: settings = $widgetSettingsStore.settings;

  $: marginRight = settings?.position?.margin?.right ?? 0;
  $: marginBottom = settings?.position?.margin?.bottom ?? 0;
  $: marginLeft = settings?.position?.margin?.left ?? 0;
  $: isRightAlign = settings?.position?.align === 'BOTTOM_RIGHT';

  $: assetsUrl = `${ASSETS_URL}/build/assets`;

  $: toggleBtnStyle = `
  margin-right: ${marginRight}px;
  margin-bottom: ${marginBottom}px;
  margin-left: ${marginLeft}px;
  ${isRightAlign ? 'right: 0' : 'left: 0'};
  ${isRightAlign ? '' : 'transform: scaleX(-1);'}

`;

  let opened = false;
  let evaIconHovered = false;
  let timeoutId;

  onMount(() => {
    if (innerWidth <= 599) {
      evaIconHovered = true;
      widgetSettingsStore.hideHints();
    }
  });

  const evaMouseenterHandler = () => {
    if (innerWidth <= 599 || isVisibleDot) return;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      evaIconHovered = true;
    }, 1500);
  };

  const evaMouseleaveHandler = () => {
    if (innerWidth <= 599) return;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      evaIconHovered = false;
    }, 1500);
  };

  const showWidget = async (event) => {
    opened = true;

    setTimeout(() => {
      widgetRuntimeStore.setChatVisibility(true);
    }, 200);

    if (!isConnectionEstablished) {
      await connectWebSocket(currentPath);
    }

    if (event?.detail?.callback) event.detail.callback();
  };
</script>

<svelte:window bind:innerWidth />
<div
  class="toggle-btn-wrapper"
  id="evahelp-ai-toggle-btn"
  style={toggleBtnStyle}
>
  <div class="toggle-btn" class:toggler-fly-out={opened}>
    <Hints on:question-click={showWidget} />
    <PulsatingDot />
    {#if isVisibleIcon}
      {#if evaIconHovered && !isVisibleHints}
        <div
          class="close-container"
          class:close-container-mirrored={!isRightAlign}
        >
          <Tooltip tooltip={$t('evahelper.widget.hideIcon')}>
            <div
              on:mouseenter={evaMouseenterHandler}
              on:mouseleave={evaMouseleaveHandler}
              on:click={widgetSettingsStore.hideHintsAndIcon}
              transition:fade={{ delay: 0, duration: 200 }}
              class="close-wrapper"
            >
              <CloseIcon />
            </div>
          </Tooltip>
        </div>
      {/if}
      <div
        class="eva-icon-wrapper"
        on:mouseenter={evaMouseenterHandler}
        on:mouseleave={evaMouseleaveHandler}
        on:click={showWidget}
        transition:fade={{ delay: 0, duration: 200 }}
      >
        <EvaIcon />
      </div>
    {/if}
  </div>
</div>
<Curtain />

<style>
  :global(#evahelper-ai) .close-container {
    & [data-tooltip]:after {
      display: none;
    }

    & [data-tooltip]:before {
      bottom: 40px;
      left: -14px;
      min-width: 130px;
    }
  }

  :global(#evahelper-ai) .close-container-mirrored {
    & [data-tooltip]:before {
      left: -84px;
      transform: scaleX(-1) !important;
    }
  }

  :global(#evahelper-ai) .close-wrapper {
    right: -65px;
    bottom: 25px;
    position: absolute;
    transform: scale(0.5, 0.5);
    cursor: pointer;
  }

  :global(#evahelper-ai) .eva-icon-wrapper {
    animation-name: flyIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  :global(#evahelper-ai) .toggle-btn-wrapper {
    z-index: 999999 !important;
    position: fixed;
    bottom: 0;
    padding-right: env(safe-area-inset-right, 0);
  }

  :global(#evahelper-ai) .toggle-btn {
    padding: 0;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: max-content;
    border: none;
    background-color: transparent;
    animation-name: flyIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  :global(#evahelper-ai) .toggler-fly-out {
    animation-name: flyOut;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes flyIn {
    from {
      transform: translateX(200px);
      opacity: 0;
    }

    to {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes flyOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }

    to {
      transform: translate(200px);
      opacity: 0;
    }
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .close-container {
      & [data-tooltip]:after {
        display: none;
      }

      & [data-tooltip]:before {
        display: none;
      }
    }

    :global(#evahelper-ai) .close-wrapper {
      right: -50px;
      bottom: 15px;
      position: absolute;
      transform: scale(0.5, 0.5);
      cursor: pointer;
    }
  }
</style>
