<script>
  import { createEventDispatcher } from 'svelte';
  import { cubicOut } from 'svelte/easing';
  import { split_css_unit } from 'svelte/internal';

  const modal = (
    node,
    {
      delay = 0,
      duration = 400,
      easing = cubicOut,
      x = 0,
      y = 0,
      opacity = 0,
    } = {}
  ) => {
    const style = getComputedStyle(node);
    const target_opacity = +style.opacity;
    const transform = style.transform === 'none' ? '' : style.transform;
    const od = target_opacity * (1 - opacity);
    const [xValue, xUnit] = split_css_unit(x);
    const [yValue, yUnit] = split_css_unit(y);
    return {
      delay,
      duration,
      easing,
      css: (t, u) => `
			transform: ${transform} translate(${(1 - t) * xValue}${xUnit}, ${
        (1 - t) * yValue
      }${yUnit}) scale(${t});
			opacity: ${target_opacity - od * u}`,
    };
  };

  const dispatch = createEventDispatcher();

  function closeModal() {
    dispatch('close');
  }
</script>

<div class="modal-background" on:click={closeModal} />

<div
  aria-modal="true"
  class="modal"
  in:modal={{ delay: 150, duration: 500, x: '-100%' }}
  out:modal={{ delay: 150, duration: 500, x: '100%' }}
  role="dialog"
>
  <slot />
</div>

<style>
  .modal-background {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    border-radius: 20px;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);

    @media (max-width: 599px) {
      & {
        border-radius: 10px;
      }
    }
  }

  .modal {
    position: fixed;
    width: 60%;
    height: fit-content;
    display: block;
    background-color: transparent;
    left: 50%;
    top: 50%;
    z-index: 9999999;
    overflow: visible;
    box-shadow: 0 0 10px hsl(0 0% 0% / 10%);
    transform: translate(-50%, -50%);
    transition: transform 1s ease-out;

    @media (max-width: 599px) {
      & {
        width: 80%;
      }
    }
  }
</style>
