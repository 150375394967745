<script>
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import Arrow from '../../assets/images/arrow.svelte';
  import { fade } from 'svelte/transition';

  $: color = $widgetSettingsStore.settings.color;
  $: isVisibleIcon = $widgetSettingsStore.isVisibleIcon;
  $: isLeftAlign =
    $widgetSettingsStore.settings?.position?.align === 'BOTTOM_LEFT';

  const onCurtainClickHandler = () => {
    widgetSettingsStore.showIcon();
  };
</script>

{#if !isVisibleIcon}
  <div
    class="curtain-container"
    class:curtain-container-mirrored={isLeftAlign}
    style="--color:{color}"
    on:click={onCurtainClickHandler}
    transition:fade={{ delay: 100, duration: 200 }}
  >
    <Arrow />
  </div>
{/if}

<style>
  :global(#evahelper-ai) .curtain-container {
    position: fixed;
    display: none;
    cursor: pointer;
    right: -2px;
    bottom: 42px;
    background-color: var(--color);
    height: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :global(#evahelper-ai) .curtain-container-mirrored {
    left: -2px;
    right: auto;
    transform: scaleX(-1);
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .curtain-container {
      display: flex;
      align-items: center;

      & svg {
        height: 16px;
        width: 16px;
      }
    }
  }
</style>
