<script>
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';

  const color = $widgetSettingsStore.settings.color;
  const shadowColor = $widgetSettingsStore.settings.shadowColor;

  $: isVisibleHints = $widgetSettingsStore.isVisibleHints;
</script>

<div
  class="close-icon"
  style="--shadowColor:{isVisibleHints ? 'rgb(255,255,255,0.4)' : shadowColor};"
>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      style="--color:{isVisibleHints ? 'white' : color};"
      class="close-icon__circle"
      cx="11"
      cy="11"
      r="11"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.76993 5.72018C6.47964 5.42856 6.00899 5.42856 5.71869 5.72018C5.4284 6.0118 5.4284 6.48461 5.71869 6.77623L9.95292 11.0299L5.77481 15.2271C5.48452 15.5187 5.48452 15.9915 5.77481 16.2832C6.0651 16.5748 6.53576 16.5748 6.82605 16.2832L11.0042 12.0859L15.1798 16.2807C15.4701 16.5723 15.9408 16.5723 16.231 16.2807C16.5213 15.9891 16.5213 15.5163 16.231 15.2247L12.0554 11.0299L16.2872 6.77869C16.5775 6.48707 16.5775 6.01426 16.2872 5.72264C15.9969 5.43102 15.5262 5.43102 15.2359 5.72264L11.0042 9.97381L6.76993 5.72018Z"
      fill={isVisibleHints ? '#C7C7C7' : '#F2F2F7'}
    />
  </svg>
</div>

<style>
  :global(#evahelper-ai) .close-icon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    opacity: 0.95;
    box-shadow: 6px 6px 20px var(--shadowColor);
  }

  :global(#evahelper-ai) .close-icon__circle {
    fill: var(--color);
  }

  :global(#evahelper-ai) .close-icon:hover {
    opacity: 1;
  }
</style>
