import { derived, writable } from 'svelte/store';
import { wrapWithHashParam } from './../Index';
import { EVA_LOCALES } from '../contants.js';

function createTranslator() {
  const { subscribe, update } = writable({
    locales: {},
    currentLanguage: null,
  });

  return {
    subscribe,
    changeCurrentLanguage: (language) => {
      update((state) => ({
        ...state,
        currentLanguage: language,
      }));
    },
    addLocale(locale, language) {
      update((state) => ({
        ...state,
        locales: {
          ...state.locales,
          [language]: locale,
        },
      }));
    },
  };
}

export const translator = createTranslator();

export const t = derived(translator, ($translator) => {
  return (key) => {
    const translate =
      $translator.locales[$translator.currentLanguage] &&
      $translator.locales[$translator.currentLanguage][key];

    return translate || key;
  };
});

export async function translateAndSaveLanguage(language) {
  const lang =
    language ||
    getLanguage(
      (
        window.evahelperCurrentLanguage || navigator.language.slice(0, 2)
      ).toLowerCase()
    );

  await getLocale(lang);
}

async function getLocale(lang) {
  const locales = JSON.parse(localStorage.getItem(EVA_LOCALES));
  let locale = null;

  const response = await fetch(
    wrapWithHashParam(`${ASSETS_URL}/build/assets/locales/${lang}.json`)
  );

  const result = await response.json();

  locale = result;

  localStorage.setItem(
    EVA_LOCALES,
    JSON.stringify({
      ...(locales && locales),
      [lang]: result,
    })
  );

  translator.changeCurrentLanguage(lang);
  translator.addLocale(locale, lang);
}

function getLanguage(lang) {
  switch (lang) {
    case 'ru':
      return 'ru';
    case 'en':
    default:
      return 'en';
  }
}

Object.defineProperty(window, 'evahelperCurrentLanguage', {
  configurable: true,
  set(value) {
    getLocale(getLanguage(value));
  },
});
