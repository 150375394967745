export const SHOW_HINTS_KEY = 'eva-show-hints';
export const SHOW_ICON_KEY = 'eva-show-icon';
export const EVA_LOCALES = 'evahelper_locales';
export const LOCALES_GAG = '{BOT_NAME}';

export const defaultWidgetPosition = {
  align: 'BOTTOM_RIGHT',
  margin: {
    top: 0,
    bottom: 18,
    right: 13,
    left: 0,
  },
};
