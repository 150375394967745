const TAIL_SLASH_OR_EXT_PATTERN = /(\.html?|\/)$/g;
const removeQueryParams = (url) => url.replace(/\?.*$/, '');
const createCourseRouteRegex = (coursePath) => {
  return new RegExp(`^${coursePath}\\b(\/.+)?$`);
};

/**
 *
 * @param {string} pathname current url
 * @param {string} query current query
 * @param {string[]} paths list of courses url
 * @returns {string|null}
 */

export const matchPathnameWithCourseUrl = (pathname, query, paths) => {
  const matched = [];
  let rootCoursePath = null;

  const trimmedPathname = pathname.replace(TAIL_SLASH_OR_EXT_PATTERN, '');

  for (let coursePath of paths) {
    const trimmedCoursePath = coursePath.replace(TAIL_SLASH_OR_EXT_PATTERN, '');

    if (coursePath === '/') {
      rootCoursePath = coursePath;
      continue;
    }

    const regex = createCourseRouteRegex(trimmedCoursePath);
    if (trimmedCoursePath === trimmedPathname) {
      matched.push(coursePath);
      continue;
    }

    if (
      removeQueryParams(trimmedCoursePath) ===
      removeQueryParams(trimmedPathname)
    ) {
      matched.push(coursePath);
    }

    if (regex.test(trimmedPathname)) {
      matched.push(coursePath);
    }
  }

  if (matched.length) {
    const sorted = matched.sort((a, b) => a.localeCompare(b));
    return sorted[sorted.length - 1];
  }

  return rootCoursePath;
};
