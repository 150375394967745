import { v4 } from 'uuid';
import { writable } from 'svelte/store';
import cloneDeep from 'lodash.clonedeep';

function createMessageStore() {
  const { update, subscribe } = writable({
    page: 0,
    size: 10,
    isLastPage: false,
    sortDirection: 'DESC',
    messageIds: [],
    messages: {},
    typingMessageId: null,
    isUserMsgSend: false,
  });

  const fillMessage = (message) => {
    const newMessage = {
      ...message,
      id: message.id || v4(),
    };

    return newMessage;
  };

  const deleteTypingMessage = () => {
    update((state) => {
      const messages = cloneDeep(state.messages);
      delete messages[state.typingMessageId];
      return {
        ...state,
        messages,
        messageIds: state.messageIds.filter(
          (messageId) => messageId !== state.typingMessageId
        ),
        typingMessageId: null,
      };
    });
  };

  const pushMessageId = (messageId, messageIds) => [...messageIds, messageId];
  const unshiftMessageId = (messageId, messageIds) => [
    messageId,
    ...messageIds,
  ];

  const getMessageStoreMutation = (addNewMessageIdToStore) => (message) => {
    const newMessage = fillMessage(message);

    return (state) => {
      const messages = cloneDeep(state.messages);
      let messageIds = state.messageIds;

      if (!state.messages[newMessage.id])
        messageIds = addNewMessageIdToStore(newMessage.id, messageIds);

      messages[newMessage.id] = newMessage;

      return {
        ...state,
        messages,
        messageIds,
      };
    };
  };

  const addMessage = (message) => {
    if (!message.text && message.senderId && !message.typing) {
      return;
    }
    update(getMessageStoreMutation(unshiftMessageId)(message));
  };

  const unshiftMessage = (message) =>
    update(getMessageStoreMutation(pushMessageId)(message));
  const saveMessages = (messages) => messages.forEach(unshiftMessage);

  const addTypingMessage = () => {
    const typingMessage = fillMessage({
      typing: true,
    });
    addMessage(typingMessage);
    update((state) => ({ ...state, typingMessageId: typingMessage.id }));
  };

  const incrementPage = () =>
    update((state) => ({ ...state, page: state.page + 1 }));

  const setIsUserMsgSend = (isUserMsgSend) => {
    update((state) => ({ ...state, isUserMsgSend }));
  };

  const setMsgReaction = (msgId, reaction) => {
    update((state) => {
      const messages = cloneDeep(state.messages);
      messages[msgId].reaction = {
        reaction,
      };
      return {
        ...state,
        messages,
      };
    });
  };

  const clearStore = () =>
    update(() => ({
      page: 0,
      size: 10,
      sortDirection: 'ASC',
      messageIds: [],
      messages: {},
      typingMessageId: null,
    }));

  const saveIsLastPage = (pageLength) =>
    update((state) => ({ ...state, isLastPage: pageLength < state.size }));

  const handleMessages = (payload) => {
    const result = JSON.parse(payload.body);

    if (Array.isArray(result)) {
      saveMessages(result);
      saveIsLastPage(result.length);
    } else {
      if (!result.typing) {
        addMessage(result);
        deleteTypingMessage();
      } else {
        addTypingMessage();
      }
    }
  };

  return {
    update,
    subscribe,
    clearStore,
    incrementPage,
    handleMessages,
    setIsUserMsgSend,
    setMsgReaction,
  };
}

export const messagesStore = createMessageStore();
