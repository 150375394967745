<script>
  import { fade } from 'svelte/transition';
  import CodeBlock from './CodeBlock.svelte';
  import CodeWord from './CodeWord.svelte';
  import MessageReacton from './MessageReacton.svelte';
  import { afterUpdate, onMount, tick } from 'svelte';
  import DislikeSvg from '../../assets/images/thumb-down.svelte';
  import LikeSvg from '../../assets/images/thumb-up.svelte';
  import { setReaction } from '../../services/socketConnection.js';
  import { conversation } from '../../storages/user-store.js';
  import { messagesStore } from '../../storages/message-store.js';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import LinkText from './LinkText.svelte';
  import { marked } from 'marked';

  export let sendTime;
  export let code;
  export let retryHandler;
  export let message;
  export let isFirstOpen;
  export let isScrollButtonShowed;
  export let isFullScreen = false;
  export let isLastEvaMsg = false;
  export let isFirstEvaMsg = false;
  $: color = $widgetSettingsStore.settings.color;

  let container;
  let showMiniReactions = false;
  let isHovered = false;
  let hasScrolledToMessage = false;

  onMount(() => {
    const msgWidth = container.getBoundingClientRect().width;
    showMiniReactions = msgWidth <= 220;
  });

  afterUpdate(async () => {
    if (
      isLastEvaMsg &&
      !hasScrolledToMessage &&
      !isFirstOpen &&
      !isScrollButtonShowed
    ) {
      const parent = container.parentElement;
      parent.scrollTop = 0;
      const scrollHeight = container.scrollHeight;
      const minMessageHeightAndGap = 59;
      parent.scrollTop -= scrollHeight - minMessageHeightAndGap;
    }
    hasScrolledToMessage = true;
  });

  const setIsHovered = (value) => () => {
    isHovered = value;
  };

  const handleRemoveReactionClick = () => {
    isHovered = false;
    setReaction(message.id, null, 'REACTION', $conversation.id);
    messagesStore.setMsgReaction(message.id, null);
  };
</script>

<div
  bind:this={container}
  class="received-message"
  class:isFullScreen
  in:fade
  on:mouseenter={setIsHovered(true)}
  on:mouseleave={setIsHovered(false)}
>
  <p class="received-message__text">
    {#each code as group}
      {#if group.type === 'code-block'}
        <CodeBlock language={group.language} code={group.content} />
      {:else if group.type === 'code-word'}
        <CodeWord>{@html group.content}</CodeWord>
      {:else if group.type === 'link-text'}
        <LinkText content={group.content} />
      {:else}
        <span class="plain-markdown" style="--color: {color}">
          {@html marked(group.content)}
        </span>
      {/if}
    {/each}
  </p>
  <div class="msg-footer">
    {#if message.reaction?.reaction}
      <div
        class="cloud"
        style="--color: {color}"
        on:click={handleRemoveReactionClick}
      >
        {#if message.reaction.reaction === '👍'}
          <LikeSvg on:click={handleRemoveReactionClick} fillColor="#fff" />
        {:else}
          <DislikeSvg on:click={handleRemoveReactionClick} fillColor="#fff" />
        {/if}
      </div>
    {/if}
    <time class="received-message__time">
      {sendTime}
    </time>
  </div>
  <MessageReacton
    {message}
    {retryHandler}
    msgReaction={message?.reaction?.reaction}
    {setIsHovered}
    {showMiniReactions}
    showReactions={isHovered && !isFirstEvaMsg}
    showRetry={isHovered && isLastEvaMsg}
  />
</div>

<!-- TODO get rid of global styles -->
<style>
  :global(#evahelper-ai) .received-message {
    position: relative;
    z-index: 1;
    background: #f2f2f7;
    border-radius: 12px;
    color: black;
    width: fit-content;
    max-width: 380px;
    padding: 6px 10px;
    box-sizing: border-box;
    align-self: flex-start;
    transition: 300ms max-width;
  }

  :global(#evahelper-ai) .received-message.isFullScreen {
    max-width: 100%;
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .received-message {
      max-width: 100%;
    }
  }

  .msg-footer {
    display: flex;
    gap: 6px;
    height: 20px;
    justify-content: flex-end;
    align-items: flex-end;

    & .cloud {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: var(--color);
      border-radius: 50%;

      & svg {
        width: 20px !important;
        height: 13.33px !important;
      }
    }
  }

  :global(#evahelper-ai) .received-message__time {
    display: block;
    text-align: right;
    font-weight: 300;
    font-size: 12px;
    margin: 0;
  }

  .received-message__text {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    word-wrap: break-word;
    margin: 0 0 2px 0;
  }

  :global(#evahelper-ai) .received-message .received-message__text,
  :global(#evahelper-ai) .received-message .received-message__time {
    color: black;
  }
</style>
