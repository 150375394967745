import css from 'svelte-highlight/languages/css';
import cpp from 'svelte-highlight/languages/cpp';
import csharp from 'svelte-highlight/languages/csharp';
import vbscriptHtml from 'svelte-highlight/languages/vbscript-html';
import javascript from 'svelte-highlight/languages/javascript';
import java from 'svelte-highlight/languages/java';
import python from 'svelte-highlight/languages/python';

const codeStyles =
  'color:#f8f8f2 !important;' +
  'background: #2B2B2B;' +
  'opacity: 80%;' +
  'hyphens: auto;' +
  '-webkit-hyphens: none;' +
  '-moz-hyphens: auto;' +
  '-ms-hyphens: auto;' +
  'padding:.1em;' +
  'border-radius:.3em;' +
  'white-space:normal;';

const codeLanguageMatcher = {
  css: css,
  cpp: cpp,
  csharp: csharp,
  html: vbscriptHtml,
  jsx: javascript,
  javascript: javascript,
  java: java,
  python: python,
};

export const useCodeFormatter = () => {
  const encodeHTMLEntities = (text) => {
    let textArea = document.createElement('textarea');
    textArea.innerText = text;
    return textArea.innerHTML;
  };

  const getHlCodeLanguage = (language) => {
    return codeLanguageMatcher[language] || javascript;
  };

  function getHighlightedWord(content, leftBacktick, group) {
    if (group.indexOf('<br>') > -1 || isValidUrl(content)) return content;
    if (!group.length) return content;
    return `<code class="language-" style="${codeStyles}">${group}</code>`;
  }

  const isValidUrl = (text) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    return regex.test(text);
  };

  const getCodeContent = (text) => {
    const [firstLine, ...otherContent] = text?.split('\n');

    if (otherContent.length) {
      return {
        language: firstLine,
        content: otherContent.join('\n'),
        type: 'code-block',
      };
    }

    return {
      content: text,
      type: 'code-word',
    };
  };

  const tripleBackticksReducer = (state, nextLine) => {
    const { accumulatedLines, inCodeBlock } = state;

    if (inCodeBlock) {
      const lastLineIndex = accumulatedLines.length - 1;
      accumulatedLines[lastLineIndex] += '\n' + nextLine;
    } else {
      accumulatedLines.push(nextLine);
    }

    return {
      accumulatedLines,
      inCodeBlock: nextLine.startsWith('```')
        ? !state.inCodeBlock
        : state.inCodeBlock,
    };
  };

  const formatTextToCodeTokens = (content) => {
    const { accumulatedLines } =
      content?.split('\n')?.reduce(tripleBackticksReducer, {
        accumulatedLines: [],
        inCodeBlock: false,
      }) || [];
    let assemblyCode = [];

    return accumulatedLines?.reduce((acc, line) => {
      const trimmed = line.trim();
      const lastElement = acc[acc.length - 1];

      if (
        trimmed.startsWith('```') &&
        assemblyCode.length === 0 &&
        !trimmed.endsWith('```')
      ) {
        assemblyCode.push(trimmed);
        return acc;
      }

      if (trimmed.endsWith('```') && assemblyCode.length > 0) {
        assemblyCode.push(trimmed);
        const assemblyCodeLine = assemblyCode.join('\n');
        const code = assemblyCodeLine.replaceAll('```', '');
        acc.push(getCodeContent(code));
        assemblyCode = [];
        return acc;
      }

      if (assemblyCode.length > 0 && !trimmed.startsWith('```')) {
        assemblyCode.push(trimmed);
        return acc;
      }

      if (trimmed.startsWith('```') && trimmed.endsWith('```')) {
        const code = line.slice(3, trimmed.length - 3);

        acc.push(getCodeContent(code));
        return acc;
      }

      let processedLine = encodeHTMLEntities(
        line.length === 0 ? line : line + '\n'
      ).replace(/(`)([^`]*)(`)/g, getHighlightedWord);

      if (isValidUrl(trimmed)) {
        acc.push({
          content: processedLine,
          type: 'link-text',
        });
        return acc;
      }
      const cleanedLine = processedLine.replace('<br>', '\n');
      const lineLength = cleanedLine.trim().length;

      if (lineLength === 0 && lastElement) {
        lastElement.content += '\n';
        return acc;
      }

      if (lastElement?.type === 'plain-text') {
        lastElement.content += cleanedLine;
        return acc;
      }

      acc.push({
        content: cleanedLine,
        type: 'plain-text',
      });
      return acc;
    }, []);
  };

  return {
    encodeHTMLEntities,
    formatTextToCodeTokens,
    getHlCodeLanguage,
  };
};
