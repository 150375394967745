<script>
  import { beforeUpdate, onDestroy, onMount } from 'svelte';

  import { t } from '../../storages/translator';
  import { sendNewMessage } from './../../services/socketConnection';
  import { debounce } from '../../helpers/index';
  import SendButtonIcon from '../../assets/images/sendButton.svelte';
  import SendButtonWithLinesIcon from '../../assets/images/sendButtonWithLines.svelte';
  import { messagesStore } from '../../storages/message-store.js';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';

  const assetsUrl = `${ASSETS_URL}/build/assets`;

  let value = '';
  let input = null;
  let isSubmitBtnDisabled = true;
  $: color = $widgetSettingsStore.settings.color;
  $: showNamePlate = $widgetSettingsStore.settings.showNamePlate;

  onMount(() => {
    document.addEventListener('keypress', sendMessageByEnter);
    setFocus();

    input.style.height = '20px';
  });

  beforeUpdate(() => {
    isSubmitBtnDisabled = !value || Boolean($messagesStore.typingMessageId);
  });

  onDestroy(() => {
    document.removeEventListener('keypress', sendMessageByEnter);
  });

  function clearTextarea() {
    value = '';
    input.value = '';
    input.style.height = '20px';
  }

  function sendMessageByEnter(event) {
    if (event.keyCode === 13 && !event.shiftKey && value) {
      createTextMessage();
    }
  }

  const handleInput = debounce(function (event) {
    value = event.target.value;
    updateTextareaHeight(value);
  }, 50);

  function handleKeypress(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();

      if (value?.length) {
        createTextMessage();
      }
    } else {
      handleInput(event);
    }
  }

  function setFocus() {
    input.blur();
    input.focus();
  }

  function createTextMessage() {
    if (isSubmitBtnDisabled) return;
    sendNewMessage(value);
    messagesStore.setIsUserMsgSend(true);
    clearTextarea();
  }

  function updateTextareaHeight(value) {
    input.style.height = '20px';
    const numberOfLineBreaks = (value.match(/\n/g) || []).length;
    let newHeight = 20 + numberOfLineBreaks * 20;
    input.style.height = newHeight + 'px';
  }
</script>

<form
  class="form"
  on:submit|preventDefault={createTextMessage}
  style={showNamePlate ? 'border-radius: none;' : 'border-radius: inherit;'}
>
  <textarea
    bind:this={input}
    bind:value
    class="field"
    on:click={setFocus}
    on:keydown={handleKeypress}
    placeholder={$t('evahelper.textarea.placeholder')}
  />
  <button
    class="button"
    disabled={isSubmitBtnDisabled}
    style="--color:{color};"
    type="submit"
  >
    {#if isSubmitBtnDisabled}
      <SendButtonIcon />
    {:else}
      <SendButtonWithLinesIcon />
    {/if}
  </button>
</form>

<style>
  :global(#evahelper-ai) .form {
    z-index: 1;
    display: flex;
    padding: 7px 18px;
    margin-top: auto;
    box-sizing: border-box;
    border-top: 1px solid #ebeef5;
    align-items: center;
    border-radius: inherit;
    background-color: #ffffff;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  :global(#evahelper-ai) .field {
    resize: none;
    box-sizing: border-box;
    max-height: 200px;
    margin-right: 5px;
    min-height: 20px;
    padding: 0;
    flex: 1;
    color: #27333e;
    border: none;
    outline: none;
    overflow: auto;
    box-shadow: none;
    transition: all 0.1s ease;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    background-color: white;
  }

  :global(#evahelper-ai) .button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    width: 36px;
    height: 36px;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 36px;
      height: 26px;
    }

    &:hover {
      &:not(:disabled) svg path {
        stroke: var(--color);
      }

      box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.12) !important;
      border-radius: 50% !important;
    }
  }

  :global(#evahelper-ai) .button:disabled {
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      & .icon-path {
        fill: #dadada;
      }
    }
  }

  @media (max-width: 599px) {
    .field {
      max-height: 100px;
    }
  }
</style>
