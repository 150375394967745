<script>
  import { createEventDispatcher } from 'svelte';
  import { widgetRuntimeStore } from '../../storages/widgetRuntimeStore.js';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import { sendNewMessage } from './../../services/socketConnection';
  import CloseIcon from './../CloseIcon/index.svelte';
  import { clickOutside } from '../../directives/clickOutside.js';
  import { t } from '../../storages/translator.js';
  import { chooseRandomValueFromList } from '../../helpers/chooseRandomValueFromList.js';
  import Tooltip from '../Tooltip/index.svelte';
  import { replaceLocalesGag } from '../../helpers/replaceLocalesGag.js';

  const dispatch = createEventDispatcher();
  const shadowColor = $widgetSettingsStore.settings.shadowColor;
  const color = $widgetSettingsStore.settings.color;
  const botName = $widgetSettingsStore.settings.botName;

  const settings = $widgetSettingsStore.settings;

  const currentCourse =
    $widgetSettingsStore.courses[$widgetRuntimeStore.currentPath];
  const miniWelcomeMessage =
    currentCourse?.miniWelcomeMessage ?? settings.miniWelcomeMessage;
  const actualHint = chooseRandomValueFromList(
    currentCourse?.hints ?? settings.hints
  );

  $: isLeftAlign = settings?.position?.align === 'BOTTOM_LEFT';
  $: isShowHints = actualHint && $widgetSettingsStore.isVisibleHints;
  let isCloseOptionsVisible = false;

  const handleQuestionClick = () => {
    dispatch('question-click', {
      callback: () => {
        setTimeout(() => sendNewMessage(actualHint), 500);
      },
    });
  };

  const handleMiniMsgClick = () => {
    dispatch('question-click');
  };

  const toggleCloseOptions = (value) => () => {
    isCloseOptionsVisible = value;
  };
</script>

<div class="hints-form" id="evahelp-ai-hints">
  <div
    on:click={toggleCloseOptions(false)}
    on:click_outside={toggleCloseOptions(false)}
    use:clickOutside
    class="hints-form-inner"
  >
    {#if isShowHints}
      {#if isCloseOptionsVisible}
        <div class="closeOption" class:close-option-mirrored={isLeftAlign}>
          <Tooltip tooltip={$t('evahelper.widget.hideHintsTooltip')}>
            <div
              class="message welcome-message"
              on:click={widgetSettingsStore.hideHints}
            >
              {$t('evahelper.widget.hideHints')}
            </div>
          </Tooltip>
        </div>
        <div class="closeOption" class:close-option-mirrored={isLeftAlign}>
          <Tooltip tooltip={$t('evahelper.widget.hideHintsAndIconTooltip')}>
            <div
              class="message welcome-message"
              on:click={widgetSettingsStore.hideHintsAndIcon}
            >
              {replaceLocalesGag(
                $t('evahelper.widget.hideHintsAndIcon'),
                botName
              )}
            </div>
          </Tooltip>
        </div>
      {/if}
      <div class="welcome-container">
        <div
          class:welcome-message-mirrored={isLeftAlign}
          class="message welcome-message"
          on:click={handleMiniMsgClick}
        >
          {miniWelcomeMessage}
        </div>
        <button
          type="button"
          class="close-btn"
          on:click|stopPropagation={toggleCloseOptions(!isCloseOptionsVisible)}
        >
          <CloseIcon />
        </button>
      </div>
      <div
        class:welcome-message-mirrored={isLeftAlign}
        class="message hint-message"
        style="--color:{color}; --shadowColor:{shadowColor};"
        on:click={handleQuestionClick}
      >
        {actualHint}
      </div>
    {/if}
  </div>
</div>

<!-- TODO get rid of global styles -->
<style>
  :global(#evahelper-ai) .hints-form {
    position: fixed;
    bottom: 66px;
    right: 0;
    width: 320px;
  }

  :global(#evahelper-ai) .closeOption {
    align-self: flex-end;
  }

  :global(#evahelper-ai) .close-option-mirrored {
    transform: scaleX(-1) !important;
  }

  :global(#evahelper-ai) .hints-form-inner {
    gap: 10px;
    display: flex;
    flex-direction: column;
    cursor: default;

    & > * {
      cursor: pointer;
    }
  }

  :global(#evahelper-ai) .welcome-container {
    cursor: default;
    display: flex;
    justify-content: space-between;
  }

  :global(#evahelper-ai) .message,
  :global(#evahelper-ai) .welcome-message,
  :global(#evahelper-ai) .close-btn {
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.22, 1.08, 0.87, 1.19);

    opacity: 0;
  }

  :global(#evahelper-ai) .message {
    padding: 6px 8px;
    border-radius: 8px;
    line-height: 18px;
    margin-bottom: 0;
    max-width: 100%;
    text-align: left;
    animation-duration: 200ms;
    animation-name: flyIn;
  }

  :global(#evahelper-ai) .message:hover {
    opacity: 1 !important;
  }

  :global(#evahelper-ai) .welcome-message {
    color: black;
    box-shadow: 6px 6px 20px rgba(242, 242, 247, 0.6);
    max-width: none;
    cursor: pointer;
    width: fit-content;
    background-color: white;
    animation-delay: 400ms;
    margin-right: 6px;
  }

  :global(#evahelper-ai) .hint-message {
    color: white;
    background-color: var(--color);
    animation-delay: 0.2s;
    box-shadow: 6px 6px 20px var(--shadowColor);
  }

  :global(#evahelper-ai) .close-btn {
    max-width: 100%;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 22px;
    height: 22px;
    align-self: center;
    justify-self: right;
    background-color: transparent;
    animation-delay: 0.4s;
    animation-duration: 150ms;
    animation-name: roll;
  }

  :global(#evahelper-ai) .welcome-message-mirrored {
    transform: scaleX(-1) !important;
    text-align: right;
  }

  @keyframes flyIn {
    from {
      transform: translateX(60px);
      opacity: 0;
    }

    to {
      transform: translate(0);
      opacity: 0.95;
      pointer-events: all;
    }
  }

  @keyframes roll {
    0% {
      transform: translateX(20px) rotate(-90deg);
    }

    100% {
      transform: translateX(0) rotate(0);
      opacity: 0.95;
    }
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .hints-form {
      display: none;
      bottom: 30px;
    }
  }
</style>
