<script>
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import { t } from '../../storages/translator.js';
  import { replaceLocalesGag } from '../../helpers/replaceLocalesGag.js';
  import { fade } from 'svelte/transition';
  import { onMount } from 'svelte';

  $: isVisibleDot = $widgetSettingsStore.isVisibleDot;
  $: color = $widgetSettingsStore.settings.color;
  $: botName = $widgetSettingsStore.settings.botName;
  $: shadowColor = $widgetSettingsStore.settings.shadowColor;
  let showPulsatingControls = false;
  let timeoutId;
  let mount = false;
  $: isLeftAlign =
    $widgetSettingsStore.settings?.position?.align === 'BOTTOM_LEFT';

  onMount(() => {
    mount = true;
  });

  const showControls = () => {
    clearTimeout(timeoutId);
    mount = false;
    showPulsatingControls = true;
  };

  const hideControls = () => {
    mount = false;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      showPulsatingControls = false;
    }, 500);
  };

  const showIcon = () => {
    showPulsatingControls = false;
    widgetSettingsStore.showIcon();
  };

  const showIconAndHints = () => {
    showPulsatingControls = false;
    widgetSettingsStore.showIconAndHints();
  };
</script>

{#if isVisibleDot}
  <div
    class="ring-container"
    on:mouseenter={showControls}
    on:mouseleave={hideControls}
    in:fade={{ delay: 200, duration: 100 }}
    out:fade={{ delay: 0, duration: 100 }}
  >
    <div
      class:hidden={mount}
      class:nonClickable={!showPulsatingControls}
      class="showOptionContainer"
      class:toggler-fly-out={!showPulsatingControls && !isLeftAlign}
      class:toggler-fly-out-mirrored={!showPulsatingControls && isLeftAlign}
      class:showOptionContainer-mirrored={isLeftAlign}
    >
      <div
        class="showOption"
        on:click={showIcon}
        class:showOption-mirrored={isLeftAlign}
      >
        {replaceLocalesGag($t('evahelper.widget.showEva'), botName)}
      </div>
      <div
        class="showOption"
        on:click={showIconAndHints}
        class:showOption-mirrored={isLeftAlign}
      >
        {replaceLocalesGag($t('evahelper.widget.showEvaAndHints'), botName)}
      </div>
    </div>
    <div
      class={`circle ${showPulsatingControls ? '' : 'shadowAnimated'}`}
      style="--color:{color};--shadowColor:{shadowColor}; "
    />
  </div>
{/if}

<style>
  :global(#evahelper-ai) .showOptionContainer {
    animation-name: flyIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    position: absolute;
    bottom: 25px;
    right: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  :global(#evahelper-ai) .showOptionContainer-mirrored {
    transform: scaleX(-1) !important;
    animation-name: flyIn-mirrored;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  :global(#evahelper-ai) .hidden {
    display: none;
  }

  :global(#evahelper-ai) .nonClickable {
    pointer-events: none;
    cursor: default;
  }

  :global(#evahelper-ai) .toggler-fly-out {
    animation-name: flyOut;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }
  :global(#evahelper-ai) .toggler-fly-out-mirrored {
    animation-name: flyOut-mirrored;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  :global(#evahelper-ai) .showOption {
    cursor: pointer;
    white-space: nowrap;
    width: fit-content;
    align-self: flex-end;
    padding: 6px 8px;
    border-radius: 8px;
    color: black;
    box-shadow: 6px 6px 20px rgba(242, 242, 247, 0.6);
    background-color: white;
  }

  :global(#evahelper-ai) .showOption-mirrored {
    align-self: flex-start;
  }

  .ring-container {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 15px;
  }

  .circle {
    width: 8px;
    height: 8px;
    background-color: var(--color);
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .shadowAnimated {
    animation: shadow-animation 5s ease-out infinite;
  }

  @keyframes flyIn {
    from {
      transform: translateX(200px);
      opacity: 0;
    }

    to {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes flyIn-mirrored {
    from {
      transform: translateX(-200px);
      opacity: 0;
    }

    to {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes flyOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }

    to {
      transform: translate(200px);
      opacity: 0;
    }
  }
  @keyframes flyOut-mirrored {
    from {
      transform: translateX(0);
      opacity: 1;
    }

    to {
      transform: translate(-200px);
      opacity: 0;
    }
  }

  @keyframes shadow-animation {
    0% {
      box-shadow: 0 0 0 0 var(--shadowColor);
    }
    50% {
      box-shadow: 0 0 8px 10px var(--shadowColor);
    }
    100% {
      box-shadow: 0 0 0 0 var(--shadowColor);
    }
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .ring-container {
      display: none;
    }
  }
</style>
