<svg
  height="24"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect fill="none" height="24" width="24" />
  <g>
    <polygon
      fill="white"
      points="17.77,3.77 16,2 6,12 16,22 17.77,20.23 9.54,12"
    />
  </g>
</svg>
