import { writable } from 'svelte/store';

function createWidgetRuntimeStore() {
  const { update, subscribe } = writable({
    isVisible: false,
    isFullScreen: false,
    currentPath: '',
  });

  function toggleFullScreen() {
    update((state) => ({
      ...state,
      isFullScreen: !state.isFullScreen,
    }));
  }

  function setChatVisibility(isVisible) {
    update((state) => ({
      ...state,
      isVisible,
    }));
  }

  function setCurrentPath(currentPath) {
    update((state) => ({
      ...state,
      currentPath,
    }));
  }

  return {
    update,
    subscribe,
    setCurrentPath,
    setChatVisibility,
    toggleFullScreen,
  };
}

export const widgetRuntimeStore = createWidgetRuntimeStore();
