<script>
  export let content;
  export let isMsgFromUser = false;
  const userLinkStyles = `color:white`;
  const defaultLinkStyles = `color:black`;

  const wrapLinkWithATag = (text) => {
    const styles = isMsgFromUser ? userLinkStyles : defaultLinkStyles;
    const regex =
      /(?:\[(.*?)\]\((.*?)\))|(?:\((.*?)\))|(?:\[(.*?)\])|(?:(http[s]?:\/\/[^\s\[\]()]+))/g;
    return text.replace(regex, (match, p1, p2, p3, p4) => {
      const linkText = match;
      const linkUrl = (p1 || p2 || p3 || p4 || match).replace('<br>', '');
      if (linkText.replace('<br>', '') !== linkUrl) {
        return match.replace(
          linkUrl,
          `<a href="${linkUrl.trim()}" target="_blank" style="text-decoration: underline;${styles}">${linkUrl.trim()}</a>`
        );
      }
      return `<a href="${linkUrl}" target="_blank" style="text-decoration: underline;${styles}">${linkText}</a>`;
    });
  };
</script>

<span class="link-text">{@html wrapLinkWithATag(content)}</span>
