export const useIntersectionObserver = () => {
  let intersectionObserver;

  const createObserver = ({ root, callback, customOptions = {} }) => {
    const options = {
      root,
      rootMargin: '0px',
      threshold: 1.0,
      ...customOptions,
    };

    intersectionObserver = new IntersectionObserver(callback, options);
  };

  const disconnect = () => {
    intersectionObserver.disconnect();
    intersectionObserver = null;
  };

  const observe = (element) => {
    intersectionObserver.observe(element);
  };

  const scrollToBottom = () => {
    const { root } = intersectionObserver;

    root.scroll({ top: root.scrollHeight, behavior: 'smooth' });
  };

  return {
    scrollToBottom,
    createObserver,
    disconnect,
    observe,
  };
};
