<script>
  import { debounce } from '../../../helpers/index.js';
  import { modalStore } from '../../../storages/modalStore.js';
  import { setReaction } from '../../../services/socketConnection.js';
  import LikeSvg from '../../../assets/images/thumb-up-off.svelte';
  import DislikeSvg from '../../../assets/images/thumb-down-off.svelte';
  import { conversation } from '../../../storages/user-store.js';
  import { widgetSettingsStore } from '../../../storages/widgetSettingsStore.js';
  import { t } from '../../../storages/translator.js';

  export let message;
  export let placeholder;
  export let showLikeIcon;
  $: color = $widgetSettingsStore.settings.color;

  let input = null;
  let value = '';

  const sendReaction = () => {
    if (value) {
      setReaction(message.id, value, 'REPLY', $conversation.id);
    }
    value = '';
    input.value = '';
    input.style.height = '80px';
    modalStore.closeModal();
  };

  const handleInput = debounce(function (event) {
    value = event.target.value;
  }, 50);

  const handleKeypress = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      sendReaction();
    } else {
      handleInput(event);
    }
  };

  const crossClickHandler = () => {
    modalStore.closeModal();
  };
</script>

<div class="modal-container">
  <div class="header">
    <span class="icon">
      {#if showLikeIcon}
        <LikeSvg />
      {:else}
        <DislikeSvg />
      {/if}
    </span>
    <span> {$t('evahelper.reaction.header')} 🙏</span>
    <div class="wrapper">
      <button
        style="--color:{color};"
        class="btn"
        on:click={crossClickHandler}
        type="button"
      >
        <svg
          class="btn-icon"
          fill="none"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="icon-path"
            d="M7.3 7.57a1 1 0 0 1 1.42 0l6.36 6.37a1 1 0 0 1-1.41 1.41L8 9.7l-5.66 5.65a1 1 0 0 1-1.41-1.41L7.3 7.57Zm-.3.76v-.05h2v.05H7Z"
          />
          <path
            class="icon-path"
            d="M8.71 9.05a1 1 0 0 1-1.4 0L.93 2.69a1 1 0 1 1 1.41-1.42L8 6.93l5.65-5.66a1 1 0 1 1 1.42 1.42L8.7 9.05Zm.3-.75v.04H7V8.3h2Z"
          />
        </svg>
      </button>
    </div>
  </div>
  <div class="main-container">
    <textarea
      bind:this={input}
      bind:value
      class="feedback-field"
      on:keydown={handleKeypress}
      {placeholder}
    />
  </div>
  <div class="footer">
    <button
      style="--color:{color};"
      class={`button ${value ? '' : 'dimming'}`}
      on:click={sendReaction}
    >
      {$t('evahelper.reaction.send')}
    </button>
  </div>
</div>

<style lang="scss">
  .modal-container {
    background: #ffffff;
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: auto;

    & .header {
      display: flex;
      padding: 16px;
      gap: 8px;
      position: relative;
      align-items: center;

      & span {
        color: #000000;
      }

      & .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        background-color: #e5e5e5;

        & svg {
          width: 36px;
          height: 24px;
        }
      }
    }

    & .main-container {
      display: flex;
      height: 100%;
      padding: 16px;

      & .feedback-field {
        border-radius: 8px;
        box-sizing: border-box;
        height: 80px;
        color: #27333e;
        padding: 8px;
        flex: 1;
        resize: none;
        border: 1px solid #c7c7c7;
        outline: none;
        overflow: hidden;
        transition: all 0.1s ease;
        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }

    & .footer {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      margin-top: auto;

      & .button {
        background-color: var(--color);
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        padding: 7px 20px;
        text-align: center;
        text-decoration: none;
        transition: all 250ms;
        border: 0;
        font-size: 14px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;

        &.dimming {
          opacity: 0.5;
        }

        &:hover {
          box-shadow:
            rgba(45, 35, 66, 0.4) 0 4px 8px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
            var(--color) 0 -3px 0 inset;
          transform: scale(1.05) rotate(2deg);
        }
      }
    }

    @media (max-width: 599px) {
      & {
        border-radius: 10px;
      }
    }
  }

  .wrapper {
    margin-left: auto;
    align-self: flex-start;

    & .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 32px;
      height: 32px;
      background: white;
      border-radius: 50%;
      border: none;
      box-shadow: none;
      color: #000000;
    }

    & .btn:hover {
      box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.12);
    }

    & .btn:hover .icon-path {
      fill: var(--color);
    }

    & .btn-icon {
      width: 16px;
      height: 16px;
    }
    & .btn .icon-path {
      fill: #dadada;
    }
  }
</style>
