<script>
  import { fade } from 'svelte/transition';
  import { widgetRuntimeStore } from '../storages/widgetRuntimeStore';
  import { messagesStore } from '../storages/message-store.js';
  import { clickOutside } from './../directives/clickOutside';

  import Messages from './Messages/index.svelte';
  import Controls from './Controls/index.svelte';
  import ChatHeader from './ChatHeader/index.svelte';
  import NamePlate from './NamePlate/index.svelte';
  import { modalStore } from '../storages/modalStore.js';
  import { widgetSettingsStore } from '../storages/widgetSettingsStore.js';

  $: settings = $widgetSettingsStore.settings;
  $: isLeftAlign = settings?.position?.align === 'BOTTOM_LEFT';

  let closed = false;
  const handleCloseWidget = () => {
    closed = true;
    modalStore.closeModal();

    setTimeout(() => {
      widgetRuntimeStore.setChatVisibility(false);
    }, 200);
  };
</script>

<div
  class="message-form"
  class:isFullScreen={$widgetRuntimeStore.isFullScreen}
  class:message-form-fly-out={closed && !isLeftAlign}
  class:message-form-fly-out-mirrored={closed && isLeftAlign}
  class:message-form-mirrored={isLeftAlign}
  on:click_outside={handleCloseWidget}
  out:fade={{ delay: 200, duration: 0 }}
  use:clickOutside
>
  <div
    class="message-form-inner"
    class:message-form-fly-in={!isLeftAlign}
    class:message-form-fly-in-mirrored={isLeftAlign}
  >
    <ChatHeader
      isFullScreen={$widgetRuntimeStore.isFullScreen}
      isTyping={Boolean($messagesStore.typingMessageId)}
      on:click:close={handleCloseWidget}
      on:click:fullscreen={widgetRuntimeStore.toggleFullScreen}
    />
    <Messages />
    <Controls />
    <NamePlate />
  </div>
</div>

<style>
  :global(#evahelper-ai) .message-form {
    z-index: 999999 !important;
    box-sizing: border-box;
    position: fixed;
    bottom: 15px;
    right: 15px;
    padding-left: env(safe-area-inset-left, 0);
    padding-right: env(safe-area-inset-right, 0);
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    max-height: 560px;
    max-width: 500px;
    will-change: max-height, max-width;
    transition:
      300ms max-height,
      300ms max-width;
  }

  :global(#evahelper-ai) .message-form-inner {
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    background: white;
    border-radius: 20px;
    box-shadow: 10px 10px 34px 23px rgba(0, 0, 0, 0.04);
    height: 100%;
    width: 100%;
  }

  :global(#evahelper-ai) .message-form-fly-in {
    animation: flyIn 200ms forwards;
  }

  :global(#evahelper-ai) .message-form-fly-in-mirrored {
    animation: flyInMirrored 200ms forwards;
  }

  :global(#evahelper-ai) .isFullScreen {
    max-width: 900px;
    max-height: 100%;
  }

  :global(#evahelper-ai) .message-form-mirrored {
    left: 15px;
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .message-form {
      max-width: none;
      max-height: 100%;
    }

    :global(#evahelper-ai) .message-form-inner {
      border-radius: 10px;
      box-shadow: 6px 6px 20px 16px rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-width: 300px) {
    :global(#evahelper-ai) .message-form {
      display: none;
    }
  }

  :global(#evahelper-ai) .message-form-fly-out {
    animation-name: flyOut;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  :global(#evahelper-ai) .message-form-fly-out-mirrored {
    animation-name: flyOutMirrored;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes flyIn {
    from {
      transform: translateX(500px);
      opacity: 0;
    }

    to {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes flyOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }

    to {
      transform: translate(500px);
      opacity: 0;
    }
  }

  @keyframes flyInMirrored {
    from {
      transform: translateX(-500px);
      opacity: 0;
    }

    to {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes flyOutMirrored {
    from {
      transform: translateX(0);
      opacity: 1;
    }

    to {
      transform: translate(-500px);
      opacity: 0;
    }
  }
</style>
