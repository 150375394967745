<script>
  import lottie from 'lottie-web';
  import { afterUpdate, createEventDispatcher, onMount } from 'svelte';
  import { t } from '../../storages/translator';
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';
  import { waitForElm } from '../../helpers/waitForElm.js';

  export let isFullScreen = false;
  export let isTyping = false;

  let animationItem = null;
  let logoNode = null;
  $: botName =
    $widgetSettingsStore.settings.botName === 'Eva'
      ? $t('evahelper.eva.name')
      : $widgetSettingsStore.settings.botName;
  $: color = $widgetSettingsStore.settings.color;
  $: settings = $widgetSettingsStore.settings;
  $: isLeftAlign = settings?.position?.align === 'BOTTOM_LEFT';

  onMount(() => {
    animationItem = lottie.loadAnimation({
      path: `${ASSETS_URL}/build/eva-logo-lottie.json`,
      rendererSettings: {
        className: 'logo-icon',
      },
      renderer: 'svg',
      autoplay: false,
      container: logoNode,
    });
  });

  const changeGIFColor = async () => {
    const svg = await waitForElm(document, '.logo-icon');
    const pathElements = svg.querySelectorAll('svg g path');
    pathElements.forEach((path) => {
      const isFillWhite =
        path.getAttribute('fill') === 'rgb(255,255,255)' ||
        !path.getAttribute('fill');
      const isStrokeWhite =
        path.getAttribute('stroke') === 'rgb(255,255,255)' ||
        !path.getAttribute('stroke');
      path.style.stroke = isStrokeWhite ? 'rgb(255,255,255)' : color;
      path.style.fill = isFillWhite ? 'rgb(255,255,255)' : color;
    });
    svg.style.fill = color;
  };

  afterUpdate(() => {
    changeGIFColor();
  });

  const dispatch = createEventDispatcher();

  const handleClickCloseBtn = () => dispatch('click:close');
  const handleClickFullScreenBtn = () => dispatch('click:fullscreen');

  const handleMouseEnter = () => {
    if (animationItem) {
      animationItem.play();
    }
  };

  const handleMouseLeave = () => {
    if (animationItem) {
      animationItem.stop();
    }
  };
</script>

<header class="chat-header">
  <div class="logo-wrapper" style="--color:{color};">
    <div
      bind:this={logoNode}
      class:logo-mirrored={isLeftAlign}
      class="logo"
      on:mouseenter={handleMouseEnter}
      on:mouseleave={handleMouseLeave}
    />
    <div class="eva">
      <div class="eva-inner">
        <span>{botName}</span>
        <span class="eva-profession">{$t('evahelper.eva.role')}</span>
      </div>
      <div class="status" class:isTyping style="--color:{color};">
        {#if isTyping}
          {$t('evahelper.message.typing')}
          <div class="typing-dots">
            <div class="dot" style="--color:{color};" />
            <div class="dot" />
            <div class="dot" />
          </div>
        {:else}
          {$t('evahelper.chat_header.status.online')}
        {/if}
      </div>
    </div>
  </div>

  <div class="controls">
    <button
      class="btn fullscreen-btn"
      on:click={handleClickFullScreenBtn}
      type="button"
      style="--color:{color};"
    >
      {#if isFullScreen}
        <svg
          class="btn-icon isFullScreenIcon"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 120 120"
        >
          <path
            class="icon-path"
            d="M50.09 64.9a5 5 0 0 1 5 5v45a5 5 0 0 1-10 .01v-40h-40a5 5 0 1 1 0-10h45Zm-3.63 1.56.1-.09 7.07 7.07-.1.1-7.07-7.08Zm23.45-11.37a5 5 0 0 1-5-5v-45a5 5 0 1 1 10 0v40h40a5 5 0 0 1 0 10h-45Zm3.63-1.55-.1.09-7.07-7.08.1-.09 7.07 7.08Z"
          />
        </svg>
      {:else}
        <svg
          class="btn-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            class="icon-path"
            d="M16.85.17a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0v-8h-8a1 1 0 0 1 0-2h9Zm-.72.3.02-.01 1.41 1.41-.01.02L16.13.47ZM1.16 17.85a1 1 0 0 1-1-1v-9a1 1 0 0 1 2 0v8h8a1 1 0 1 1 0 2h-9Zm.72-.3h-.01l-1.42-1.4.02-.02 1.41 1.42Z"
          />
        </svg>
      {/if}
    </button>

    <button
      style="--color:{color};"
      class="btn"
      on:click={handleClickCloseBtn}
      type="button"
    >
      <svg
        class="btn-icon"
        fill="none"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="icon-path"
          d="M7.3 7.57a1 1 0 0 1 1.42 0l6.36 6.37a1 1 0 0 1-1.41 1.41L8 9.7l-5.66 5.65a1 1 0 0 1-1.41-1.41L7.3 7.57Zm-.3.76v-.05h2v.05H7Z"
        />
        <path
          class="icon-path"
          d="M8.71 9.05a1 1 0 0 1-1.4 0L.93 2.69a1 1 0 1 1 1.41-1.42L8 6.93l5.65-5.66a1 1 0 1 1 1.42 1.42L8.7 9.05Zm.3-.75v.04H7V8.3h2Z"
        />
      </svg>
    </button>
  </div>
</header>

<style>
  :global(#evahelper-ai) .chat-header {
    display: flex;
    padding: 0 10px;
    height: 54px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ededed;
  }

  :global(#evahelper-ai) .logo-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  :global(#evahelper-ai) .logo {
    box-sizing: border-box;
    width: 44px;
    height: 44px;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: 150ms border-color;
  }

  :global(#evahelper-ai) .logo:hover {
    border-color: var(--color);
  }
  :global(#evahelper-ai) .logo-mirrored {
    transform: scaleX(-1);
  }

  :global(#evahelper-ai) .eva {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: 14px;
    line-height: 14px;
  }

  :global(#evahelper-ai) .eva-inner {
    display: flex;
    gap: 6px;
    font-weight: bold;
    color: #222;
  }

  :global(#evahelper-ai) .eva-profession {
    color: #c7c7c7;
  }

  :global(#evahelper-ai) .status {
    display: flex;
    align-items: baseline;
    gap: 1.5px;
    color: #c7c7c7;
    font-size: 12px;
  }

  :global(#evahelper-ai) .isTyping {
    color: var(--color);
  }

  :global(#evahelper-ai) .controls {
    display: flex;
    gap: 6px;
  }

  :global(#evahelper-ai) .btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 50%;
    border: none;
    box-shadow: none;
  }

  :global(#evahelper-ai) .btn:hover {
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.12);
  }

  :global(#evahelper-ai) .btn:hover .icon-path {
    fill: var(--color);
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .fullscreen-btn {
      display: none;
    }
  }

  :global(#evahelper-ai) .btn-icon {
    width: 16px;
    height: 16px;
  }

  :global(#evahelper-ai) .isFullScreenIcon {
    width: 20px;
    height: 20px;
  }

  :global(#evahelper-ai) .icon-path {
    fill: #dadada;
    transition: 150ms fill;
  }

  :global(#evahelper-ai) .typing-dots {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  :global(#evahelper-ai) .dot {
    background: var(--color);
    width: 3px;
    height: 3px;
    border-radius: 50%;
    animation: typing 1s infinite;
  }

  :global(#evahelper-ai) .dot:nth-child(2) {
    animation-delay: 100ms;
  }

  :global(#evahelper-ai) .dot:last-child {
    animation-delay: 200ms;
  }

  @keyframes typing {
    0% {
      opacity: 0.2;
      transform: scale(0.9);
    }
    20% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0.2;
      transform: scale(0.9);
    }
  }
</style>
