<script>
  import { fade } from 'svelte/transition';
  import { t } from './../../storages/translator';
  import EvaTitle from './../EvaTitle/index.svelte';
</script>

<div class="typing-message">
  <div class="body" in:fade>
    <EvaTitle />

    <p class="text">
      <i class="dot1">{$t('evahelper.message.typing')}</i>
      <i class="dot1">.</i>
      <i class="dot2">.</i>
      <i class="dot3">.</i>
    </p>
  </div>
</div>

<!-- TODO get rid of global styles -->
<style>
  :global(#evahelper-ai) .typing-message {
    position: relative;
    z-index: 1;
    max-width: calc(500px - 140px);
    width: fit-content;
  }

  :global(#evahelper-ai) .dot1,
  :global(#evahelper-ai) .dot2,
  :global(#evahelper-ai) .dot3 {
    animation: typing 1.5s infinite forwards;
  }

  :global(#evahelper-ai) .dot2 {
    animation-delay: 0.4s;
  }

  :global(#evahelper-ai) .dot3 {
    animation-delay: 0.2s;
  }

  @keyframes typing {
    0% {
      opacity: 0.1;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }

  :global(#evahelper-ai) .body {
    background: #f2f2f7;
    border-radius: 6px;
    color: black;
    padding: 10px;
  }

  :global(#evahelper-ai) .text {
    margin: 0;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    color: black;
  }
</style>
