export const getWidgetSettings = async () => {
  const referrer = document.referrer;
  const srcQuery = referrer ? `?src=${referrer}` : '';
  const response = await fetch(`${API_URL}/widgetV2${srcQuery}`, {
    method: 'GET',
    'Content-Type': 'application/json',
  });

  const data = await response.json();

  return data;
};
