<script>
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';

  $: color = $widgetSettingsStore.settings.color;
  $: shadowColor = $widgetSettingsStore.settings.shadowColor;
</script>

<svg
  class="logo"
  style="--shadowColor:{shadowColor};"
  fill="none"
  height="477"
  id="evahelp-ai-toggle-btn-logo"
  viewBox="0 0 501 477"
  width="501"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    fill="#fff"
    height="298"
    rx="149"
    stroke={color}
    stroke-width="25"
    width="421"
    x="40.5"
    y="103.5"
  />
  <path d="m383.1 367.7 22 73.7-96.8-55.9 74.8-17.8Z" fill="#fff" />
  <path
    d="m309 401.2 109.3 62c4.4 2.4 9.3-2.5 6.8-6.9l-24.3-43a8 8 0 0 1-.5-1.4L385 368.5"
    stroke={color}
    stroke-width="25"
  />
  <rect fill={color} height="98" rx="17" width="60" x="134" y="178" />
  <rect fill={color} height="98" rx="17" width="60" x="252" y="178" />
  <rect fill="#fff" height="32" rx="11" width="22" x="133" y="195" />
  <rect fill="#fff" height="32" rx="11" width="22" x="251" y="195" />
  <path
    d="M247.9.7a10 10 0 0 1 5.2 0L465.7 59c9.8 2.6 9.8 16.6 0 19.2l-212.6 58.2a10 10 0 0 1-5.2 0L35.3 78c-9.8-2.6-9.8-16.6 0-19.2L247.9.7Z"
    fill={color}
  />
</svg>

<style>
  :global(#evahelper-ai) .logo {
    cursor: pointer;
    background: none;
    width: 55px;
    height: 55px;
    box-sizing: border-box;
    filter: drop-shadow(6px 6px 20px var(--shadowColor));
  }

  @media (max-width: 599px) {
    :global(#evahelper-ai) .logo {
      width: 38px;
      height: 38px;
    }
  }
</style>
