import SockJS from 'sockjs-client/dist/sockjs.min.js';

export function createSocketClient(id, organizationId) {
  const token = localStorage.getItem('token');
  const tokenExist =
    token && organizationId ? `&organizationId=${organizationId}` : '';
  const referrer = document.referrer;
  const srcQuery = referrer ? `&src=${referrer}` : '';
  return new SockJS(
    `${API_URL}/education?studentId=${id}${tokenExist}${srcQuery}`
  );
}

let hash = (Math.random() + 1).toString(36).substring(5);

export function wrapWithHashParam(url) {
  return url + '?hash=' + hash;
}
