<script>
  import Highlight from 'svelte-highlight';
  import { useCodeFormatter } from '../../helpers/useCodeFormatter.js';

  export let code;
  export let language;

  let isCopied = false;
  let copyTimeout;
  const { getHlCodeLanguage } = useCodeFormatter();
  const handleClick = () => {
    navigator.clipboard.writeText(code);

    isCopied = true;
    clearTimeout(copyTimeout);
    copyTimeout = setTimeout(() => {
      isCopied = false;
    }, 2000);
  };
</script>

<span class="code-wrapper">
  <button class="copy-btn" class:isCopied on:click={handleClick} type="button">
    {#if isCopied}
      Copied!
    {:else}
      <svg class="icon" focusable="false" viewBox="0 0 24 24">
        <path
          d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
        />
      </svg>
    {/if}
  </button>
  <Highlight {code} language={getHlCodeLanguage(language)} />
</span>

<style>
  :global(#evahelper-ai) .code-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 0;
    max-width: 100%;
    width: fit-content;
    margin: 10px 0;
  }

  :global(#evahelper-ai) .code-wrapper:hover .copy-btn {
    opacity: 1;
  }

  :global(#evahelper-ai) .copy-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.4;
    border-radius: 4px;
    transition:
      150ms opacity,
      150ms background-color;
    color: black;
  }

  :global(#evahelper-ai) .copy-btn:hover {
    background-color: #eaeaea;
  }

  :global(#evahelper-ai) .copy-btn.isCopied {
    opacity: 1;
    color: white;
    background-color: #272822;
  }

  :global(#evahelper-ai) .icon {
    display: block;
    fill: black;
    transition: 150ms fill;
    width: 18px;
    height: 18px;
  }
</style>
