<script>
  import { widgetSettingsStore } from '../../storages/widgetSettingsStore.js';

  $: color = $widgetSettingsStore.settings.color;
  $: showNamePlate = $widgetSettingsStore.settings.showNamePlate;
  $: language = $widgetSettingsStore.settings.language;
  $: href = language === 'RU' ? 'https://evahelp.ai/ru' : 'https://evahelp.ai';
</script>

{#if showNamePlate}
  <div class="plate">
    Powered by <a
      style="--color:{color};"
      class="eva-link"
      {href}
      target="_blank"
    >
      evahelp.ai
    </a>
  </div>
{/if}

<style>
  .plate {
    text-align: center;
    padding: 4px;
    color: #757575 !important;
    border-top: 1px solid #ededed;
    font-size: 12px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    & .eva-link {
      text-decoration: none;
      font-weight: bold;
      color: #757575 !important;

      &:visited {
        color: #757575 !important;
      }

      &:hover {
        color: var(--color) !important;
      }
    }
  }
</style>
