export const useMutationObserver = () => {
  let mutationObserver;
  const config = {
    childList: true,
    subtree: true,
  };

  const createObserver = (callback) => {
    mutationObserver = new MutationObserver(callback);
  };

  const disconnect = () => {
    mutationObserver.disconnect();
    mutationObserver = null;
  };

  const observe = (element) => {
    mutationObserver.observe(element, config);
  };

  return {
    createObserver,
    disconnect,
    observe,
  };
};
