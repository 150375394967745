<script>
  import { afterUpdate, onDestroy, onMount } from 'svelte';

  import { authStore } from './../../storages/authStore';
  import { messagesStore } from '../../storages/message-store.js';
  import { conversation } from './../../storages/user-store';
  import { widgetRuntimeStore } from '../../storages/widgetRuntimeStore.js';

  import {
    getMessages,
    sendNewMessage,
  } from './../../services/socketConnection';
  import { useIntersectionObserver } from './../../services/useIntersectionObserver';

  import Loader from './../Loader/index.svelte';
  import Message from './../message/index.svelte';
  import ScrollButton from './../ScrollButton/index.svelte';
  import { modalStore } from '../../storages/modalStore.js';
  import Modal from '../modal/index.svelte';

  let unsub;
  let element;
  let scrollerHeader;
  let scrollerFooter;
  let isScrollButtonShowed = false;
  let isFirstOpen = true;
  $: showModal = $modalStore.showModal;
  $: currentComponent = $modalStore.children.component;

  let scrollerObserver;
  let loadMessagesObserver;

  let isConnectionEstablished;

  onMount(() => {
    isFirstOpen = true;
    unsub = authStore.subscribe(({ isSocketConnectionEstablished }) => {
      isConnectionEstablished = isSocketConnectionEstablished;
      if (isSocketConnectionEstablished) {
        createScrollerObserver();
        createLoadMessagesObserver();
      }
    });
  });

  onDestroy(() => {
    scrollerObserver?.disconnect();
    loadMessagesObserver?.disconnect();
    unsub();
  });

  afterUpdate(() => {
    const isEvaTyping =
      Boolean($messagesStore.typingMessageId) && $messagesStore.isUserMsgSend;
    if ($messagesStore.isUserMsgSend) {
      isFirstOpen = false;
    }
    if (isEvaTyping) {
      setTimeout(() => {
        messagesStore.setIsUserMsgSend(false);
        isScrollButtonShowed && scrollerObserver?.scrollToBottom();
      }, 0);
    }
  });

  const checkIsEvaLastMsg = (msgId) => {
    const lastMsgId = $messagesStore?.messageIds[0];
    const isOnlyOneMsg = $messagesStore?.messageIds.length === 1;
    const { id, senderId } = $messagesStore.messages[lastMsgId];
    return msgId === id && !senderId && !isOnlyOneMsg;
  };

  const checkIsEvaFirstMsg = (msgId) => {
    const firstMsg =
      $messagesStore?.messageIds[$messagesStore?.messageIds.length - 1];
    const { id, senderId } = $messagesStore.messages[firstMsg];
    return msgId === id && !senderId;
  };

  const retryHandler = () => {
    const msgIds = $messagesStore?.messageIds;
    for (let i = 0; i < msgIds?.length; i++) {
      const msgId = msgIds[i];
      const msg = $messagesStore.messages[msgId];
      if (msg?.senderId) {
        sendNewMessage(msg.text);
        messagesStore.setIsUserMsgSend(true);
        break;
      }
    }
  };

  const createScrollerObserver = () => {
    scrollerObserver = useIntersectionObserver();

    scrollerObserver.createObserver({
      root: element,
      callback: (entries) =>
        entries.forEach(
          ({ isIntersecting }) => (isScrollButtonShowed = !isIntersecting)
        ),
    });

    if (scrollerFooter) scrollerObserver.observe(scrollerFooter);
  };

  const createLoadMessagesObserver = () => {
    loadMessagesObserver = useIntersectionObserver();

    loadMessagesObserver.createObserver({
      root: element,
      customOptions: {
        threshold: 0.0,
      },
      callback: (entries) =>
        entries.forEach(({ intersectionRatio }) => {
          if (intersectionRatio > 0 && isConnectionEstablished) {
            getMessagesList($conversation.id);
          }
        }),
    });

    if (scrollerHeader) loadMessagesObserver.observe(scrollerHeader);
  };

  const getMessagesList = (conversationId) => {
    const { size, page, sortDirection, isLastPage } = $messagesStore;
    if (!isLastPage) {
      getMessages({
        size,
        page,
        sortDirection,
        conversationId,
      });
      messagesStore.incrementPage();
    }
  };
</script>

<main bind:this={element} class="messages">
  <div bind:this={scrollerFooter} class="messages__scroller-footer" />

  {#if isConnectionEstablished}
    {#each $messagesStore.messageIds as messageId (messageId)}
      <Message
        message={$messagesStore.messages[messageId]}
        isFullScreen={$widgetRuntimeStore.isFullScreen}
        {isFirstOpen}
        {isScrollButtonShowed}
        isLastEvaMsg={checkIsEvaLastMsg(messageId)}
        isFirstEvaMsg={checkIsEvaFirstMsg(messageId)}
        {retryHandler}
      />
    {/each}
  {:else}
    <Loader />
  {/if}

  <div class="messages__scroller-header-wrapper">
    <div bind:this={scrollerHeader} class="messages__scroller-header" />
  </div>
</main>
<div class="btn-wrapper">
  {#if isScrollButtonShowed}
    <ScrollButton on:click={scrollerObserver?.scrollToBottom} />
  {/if}
</div>
{#if showModal}
  <Modal on:close={modalStore.closeModal}>
    {#if currentComponent}
      <svelte:component
        this={currentComponent}
        {...$modalStore.children.props}
      />
    {/if}
  </Modal>
{/if}

<style>
  :global(#evahelper-ai) .messages {
    display: flex;
    gap: 8px;
    width: 100%;
    flex-direction: column-reverse;
    padding: 0 18px 8px;
    overflow-y: auto;
    overscroll-behavior: contain;
    box-sizing: border-box;
  }

  :global(#evahelper-ai) .btn-wrapper {
    position: relative;
  }

  :global(#evahelper-ai) .messages::-webkit-scrollbar {
    display: none;
  }

  :global(#evahelper-ai) .messages__scroller-header-wrapper,
  :global(#evahelper-ai) .messages__scroller-footer,
  :global(#evahelper-ai) .messages__scroller-header {
    height: 0;
  }

  :global(#evahelper-ai) .messages__scroller-header-wrapper {
    position: relative;
  }

  :global(#evahelper-ai) .messages__scroller-header {
    position: absolute;
    top: 0;
    width: 100%;
  }
</style>
